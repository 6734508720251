<!--
 * @Date: 2020-02-26 02:46:09
 * @LastEditors: wxb
 * @LastEditTime: 2021-10-26 23:33:19
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\components\pastProjects.vue
 -->
<template>
  <div class="containner">
    <div class="search-panel">
      <el-form :inline="true"
               size="medium"
               :model="searchParams"
               @submit.native.prevent>
        <el-form-item>
          <el-input v-model="searchParams.projectName"
                    clearable
                    placeholder="请输入项目名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchParams.bidWinningAmount"
                    clearable
                    oninput="value = value.replace(/[^\d.]/g,'');"
                    placeholder="中标金额≥">
          </el-input>
          <span class="money-unit">万</span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="pastProjects"
              border
              style="width: 100%">
      <template slot="empty">
        <noData tips="截至当前日期，该板块下无信息公布，请持续关注，全量信息持续更新"
                v-show="noData"></noData>
        <viporlogin bgStr="5"
                    :type="viporlogin"
                    v-show="!canSee"></viporlogin>
      </template>
      <el-table-column label="序号"
                       align="center"
                       width="55">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="xiangMMCh"
                       label="项目名称"
                       align="center"></el-table-column>
      <el-table-column prop="zhongBJE"
                       label="中标金额（万元）"
                       align="center"
                       width="150">
        <template slot-scope="scope">{{scope.row.zhongBJE | formatMoney}}</template>
      </el-table-column>
      <el-table-column prop="zhongBRQ"
                       label="中标日期"
                       align="center"
                       width="150">
        <template slot-scope="scope">{{scope.row.zhongBRQ | formatterDate}}</template>
      </el-table-column>
    </el-table>
    <div class="company-page">
      <el-pagination layout="prev, slot, next"
                     background
                     :total="pastProjectsCnt"
                     @current-change="handleCurrentChange"
                     :current-page.sync="currentPage"
                     :hide-on-single-page="true">
        <span class="current-page">{{currentPage}}</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getPerformanceListByID } from '@/api/organization'
import Bus from './bus.js'
export default {
  data() {
    return {
      currentPage: 1,
      pastProjects: [],
      pastProjectsCnt: 0,
      searchParams: {
        tongYShHXYDM: '',
        start: 0,
        limit: 10,
        projectName: '',
        bidWinningAmount: ''
      },
      noData: false,
      canSee: true,
      viporlogin: 'login',
      firstLoad: true
    }
  },
  components: {
    noData,
    viporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.searchParams.tongYShHXYDM = data.tongYShHXYDM
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck()
          })
        } else {
          this.authCheck()
        }
      } else {
        this.authCheck()
      }
    })
  },
  methods: {
    onSearch() {
      this.searchParams.start = 0
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.searchParams.start = (val - 1) * this.searchParams.limit
      this.getList()
    },
    getList() {
      if (isNaN(parseFloat(this.searchParams.bidWinningAmount))) {
        this.searchParams.bidWinningAmount = ''
      } else {
        this.searchParams.bidWinningAmount = parseFloat(this.searchParams.bidWinningAmount)
      }
      getPerformanceListByID(this.searchParams).then(data => {
        if (data.ok) {
          this.pastProjects = data.lst
          this.pastProjectsCnt = data.cnt
          if (data.cnt === 0) {
            this.noData = true
          }

          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'pastpro', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    authCheck() {
      const authObj = authenticate('orgAchievement', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getList()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    }
  }
}

</script>
<style lang='scss' scoped>
/deep/ .el-table__empty-text {
  width: 100%;
}

.search-panel {
  .money-unit {
    position: absolute;
    right: 4px;
    color: #dcdcdc;
  }
}
.company-page {
  margin-top: 5px;
  text-align: right;
}
</style>
