<!--
 * @Date: 2020-02-26 02:54:38
 * @LastEditors: wxb
 * @LastEditTime: 2021-10-27 10:55:05
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\components\personLink.vue
 -->
<template>
  <div class="containner">
    <div class="persionList"
         v-show="!noData && canSee">
      <el-row :gutter="20">
        <el-col :span="6"
                v-for="(item,index) in personnelData"
                :key="index"
                style="padding-top:20px">
          <div class="box box-widget widget-user-2">
            <div class="widget-user-header bg-blue">
              <div class="widget-user-image">
                <img class="img-circle"
                     src="../../../assets/avatar.png" />
              </div>
              <h3 class="widget-user-username overflow-ellipsis">{{item.xingM}}</h3>
              <h5 class="widget-user-desc overflow-ellipsis">{{item.zhiW1}}</h5>
              <h5 class="widget-user-desc overflow-ellipsis">{{item.zhiW2}}</h5>
            </div>
            <div class="box-footer no-padding">
              <ul class="nav nav-stacked">
                <li>联系方式：{{item.lianXFSh1}}</li>
                <li>联系方式：{{item.lianXFSh2}}</li>
                <li>邮箱：{{item.youX1}}</li>
                <li>邮箱：{{item.youX2}}</li>
              </ul>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="company-page"
           style="text-align:center;">
        <el-pagination layout="prev, slot, next"
                       background
                       :page-size="8"
                       :total="personnelCnt"
                       @current-change="handleCurrentChange"
                       :current-page.sync="currentPage"
                       :hide-on-single-page="true">
          <span class="current-page">{{currentPage}}</span>
        </el-pagination>
      </div>
    </div>
    <noData tips="截至当前日期，该板块下无信息公布，请持续关注，全量信息持续更新"
            v-show="noData"></noData>
    <viporlogin bgStr="5"
                :type="viporlogin"
                v-show="!canSee"></viporlogin>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getPersonnelList } from '@/api/organization'
import Bus from './bus.js'
export default {
  data() {
    return {
      currentPage: 1,
      personnelData: [],
      personnelCnt: 0,
      searchParams: {
        companyName: '',
        start: 0,
        limit: 8,
        xingM: ''
      },
      noData: false,
      canSee: true,
      viporlogin: 'login',
      firstLoad: true
    }
  },
  components: {
    noData,
    viporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.searchParams.companyName = data.mingCh
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck()
          })
        } else {
          this.authCheck()
        }
      } else {
        this.authCheck()
      }
    })
  },
  methods: {
    handleCurrentChange(val) {
      this.searchParams.start = (val - 1) * this.searchParams.limit
      this.getList()
    },
    getList() {
      getPersonnelList(this.searchParams).then(data => {
        if (data.ok) {
          this.personnelData = data.lst
          this.personnelCnt = data.cnt
          if (data.cnt === 0) {
            this.noData = true
          }

          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'linkman', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    authCheck() {
      const authObj = authenticate('orgLinkMan', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getList()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    }
  }
}

</script>
<style lang='scss' scoped>
// 联系人信息样式
.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);

  &-widget {
    border: none;
    position: relative;
  }

  .widget-user-header {
    padding: 20px;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }

  .widget-user-image > img {
    width: 65px;
    height: auto;
    float: left;
  }

  .img-circle {
    border-radius: 50%;
  }

  img {
    vertical-align: middle;
    border: 0;
  }

  .bg-blue {
    background-color: #0073b7 !important;
    color: #fff;
  }

  .widget-user-username {
    margin-left: 75px;
  }
  .widget-user-desc {
    margin-left: 75px;
  }

  .widget-user-username {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 25px;
    font-weight: 300;
  }

  .overflow-ellipsis {
    overflow: hidden; //超出的文本隐藏
    text-overflow: ellipsis; //溢出用省略号显示
    white-space: nowrap; //溢出不换行
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
    color: inherit;
  }
  .no-padding {
    padding: 0 !important;
  }
  &-footer {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #f4f4f4;
    padding: 10px;
    background-color: #fff;
  }

  .box-header:before,
  .box-body:before,
  .box-footer:before,
  .box-header:after,
  .box-body:after,
  .box-footer:after {
    content: " ";
    display: table;
  }

  :after,
  :before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .nav {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  ul {
    margin-top: 0;
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }

  .nav-stacked > li {
    float: none;
    position: relative;
    display: block;
    border-bottom: 1px solid #f4f4f4;
    margin: 0;
    border-radius: 0;
    border-top: 0;
    border-left: 3px solid transparent;
    color: #444;
    position: relative;
    display: block;
    padding: 10px 15px;
  }

  .text-center {
    text-align: center;
  }
}

.company-page {
  margin-top: 5px;
  text-align: right;
}
</style>
