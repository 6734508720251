<!--
 * @Date: 2020-02-26 03:01:53
 * @LastEditors: wxb
 * @LastEditTime: 2021-10-22 12:44:21
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\components\exhi.vue
 -->
<template>
  <div class="containner">
    <div class="search-panel">
      <el-form :inline="true"
               size="medium"
               :model="searchParams"
               @submit.native.prevent>
        <el-form-item>
          <el-input v-model="searchParams.exhibitionName"
                    clearable
                    placeholder="请输入展会名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableDataExhi"
              border
              stripe
              style="width: 100%">
      <template slot="empty">
        <noData tips="截至当前日期，该板块下无信息公布，请持续关注，全量信息持续更新"
                v-show="noData"></noData>
        <viporlogin bgStr="5"
                    :type="viporlogin"
                    v-show="!canSee"></viporlogin>
      </template>
      <el-table-column label="序号"
                       align="center"
                       width="55">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="exhibitionName"
                       label="展会名称"
                       align="center"></el-table-column>
      <el-table-column prop="jieQ"
                       label="届期"
                       align="center"
                       width="100"></el-table-column>
      <el-table-column prop="zhanGMCh"
                       align="center"
                       label="展馆名称"
                       width="250"></el-table-column>
      <el-table-column prop="zhanGDZh"
                       label="展馆地址"
                       align="center"
                       width="250"></el-table-column>
      <el-table-column prop="canZhFW"
                       label="参展范围"
                       align="center"></el-table-column>
      <el-table-column prop="zhanPXX"
                       label="展品信息"
                       align="center"></el-table-column>
    </el-table>
    <div class="company-page">
      <el-pagination layout="prev, slot, next"
                     background
                     :total="tableDataExhiCnt"
                     @current-change="handleCurrentChange"
                     :hide-on-single-page="true"
                     :current-page.sync="currentPage">
        <span class="current-page">{{currentPage}}</span>
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getExhibitionList } from '@/api/organization'
import Bus from './bus.js'
export default {
  data() {
    return {
      currentPage: 1,
      tableDataExhi: [],
      tableDataExhiCnt: 0,
      searchParams: {
        companyName: '',
        start: 0,
        limit: 10,
        exhibitionName: ''
      },
      noData: false,
      canSee: true,
      viporlogin: 'login',
      firstLoad: true
    }
  },
  components: {
    noData,
    viporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.searchParams.companyName = data.mingCh
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck()
          })
        } else {
          this.authCheck()
        }
      } else {
        this.authCheck()
      }
    })
  },
  methods: {
    onSearch() {
      this.searchParams.start = 0
      this.currentPage = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.searchParams.start = (val - 1) * this.searchParams.limit
      this.getList()
    },
    getList() {
      getExhibitionList(this.searchParams).then(data => {
        if (data.ok) {
          this.tableDataExhi = data.lst
          this.tableDataExhiCnt = data.cnt
          if (data.cnt === 0) {
            this.noData = true
          }
          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'exhi', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    authCheck() {
      const authObj = authenticate('orgExhi', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getList()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-table__empty-text {
  width: 100%;
}

.company-page {
  margin-top: 5px;
  text-align: right;
}
</style>
