<!--
 * @Date: 2020-05-06 16:28:32
 * @LastEditors: wxb
 * @LastEditTime: 2021-10-26 23:19:15
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\components\registerPerson.vue
 -->
<template>
  <div class="containner">
    <div class="search-panel">
      <el-form :inline="true"
               size="medium"
               @submit.native.prevent
               :model="searchParams">
        <el-form-item>
          <el-input v-model="searchParams.name"
                    clearable
                    placeholder="请输入人员名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchParams.registerType"
                     clearable
                     placeholder="请选择注册类别">
            <el-option v-for="(item,index) in registTypeList"
                       :key="index"
                       :label="item.value"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input v-model="searchParams.registerMajor"
                    clearable
                    placeholder="请输入注册专业查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="awardsData"
              border
              style="width: 100%">
      <template slot="empty">
        <noData tips="截至当前日期，该板块下无信息公布，请持续关注，全量信息持续更新"
                v-show="noData"></noData>
        <viporlogin bgStr="5"
                    :type="viporlogin"
                    v-show="!canSee"></viporlogin>
      </template>
      <el-table-column label="序号"
                       align="center"
                       width="55">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="name"
                       label="姓名"
                       align="center"></el-table-column>
      <el-table-column prop="registerType"
                       label="注册类别"
                       align="center"></el-table-column>
      <el-table-column prop="registerCode"
                       label="注册号"
                       align="center"></el-table-column>
      <el-table-column prop="registerMajor"
                       label="注册专业"
                       align="center"></el-table-column>
    </el-table>
    <div class="company-page">
      <el-pagination layout="prev, slot, next"
                     background
                     :total="awardsCnt"
                     @current-change="handleCurrentChange"
                     :current-page.sync="currentPage"
                     :hide-on-single-page="true">
        <span class="current-page">{{currentPage}}</span>
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getRegisterPerson } from '@/api/organization'
import { getTypeCodeList } from '@/api/dictionary'
import Bus from './bus.js'
export default {
  data() {
    return {
      currentPage: 1,
      awardsData: [],
      awardsCnt: 0,
      searchParams: {
        tongYShHXYDM: '',
        pageNo: 0,
        limit: 10,
        name: '',
        registerType: '',
        registerMajor: ''
      },
      noData: false,
      canSee: true,
      viporlogin: 'login',
      registTypeList: [],
      firstLoad: true
    }
  },
  components: {
    noData,
    viporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.searchParams.tongYShHXYDM = data.tongYShHXYDM
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck()
          })
        } else {
          this.authCheck()
        }
      } else {
        this.authCheck()
      }
    })
  },
  methods: {
    onSearch() {
      this.searchParams.pageNo = 0
      this.currentPage = 1
      this.getRegisterPerson()
    },
    handleCurrentChange(val) {
      this.searchParams.pageNo = (val - 1) * this.searchParams.limit
      this.getRegisterPerson()
    },
    getRegisterPerson() {
      getRegisterPerson(this.searchParams).then(data => {
        if (data.ok) {
          this.awardsData = data.lst
          this.awardsCnt = data.cnt
          if (data.cnt === 0) {
            this.noData = true
          }
          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'register', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    authCheck() {
      const authObj = authenticate('orgRegister', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getRegisterPerson()
        // 获取注册类别列表
        this.getRegistType()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    getRegistType() {
      getTypeCodeList('zclb').then(res => {
        this.registTypeList = res
      }).catch(err => console.log(err))
    }
  }
}

</script>
<style lang='scss' scoped>
/deep/ .el-table__empty-text {
  width: 100%;
}

.company-page {
  margin-top: 5px;
  text-align: right;
}
</style>
