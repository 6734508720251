<!--
 * @Date: 2020-02-26 01:58:09
 * @LastEditors: wxb
 * @LastEditTime: 2021-10-26 23:18:01
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\components\qualification.vue
 -->
<template>
  <div class="containner">
    <div class="search-panel">
      <el-form :inline="true"
               size="medium"
               @submit.native.prevent
               :model="searchParams">
        <el-form-item>
          <el-input v-model="searchParams.strQualifyName"
                    clearable
                    placeholder="请输入资质名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchParams.strTopLevelCode"
                     clearable
                     placeholder="请选择资质类别">
            <el-option v-for="(item,index) in qualifyCategoryList"
                       :key="index"
                       :label="item.strQualifyName"
                       :value="item.strQualifyCode"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="searchParams.date"
                          clearable
                          type="daterange"
                          range-separator="~"
                          format="yyyy-MM-dd"
                          value-format="yyyy-MM-dd"
                          unlink-panels
                          start-placeholder="有效期开始日期"
                          end-placeholder="有效期结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="qualificationList"
              border
              stripe
              style="width: 100%">
      <template slot="empty">
        <noData tips="截至当前日期，该板块下无信息公布，请持续关注，全量信息持续更新"
                v-show="noData"></noData>
        <viporlogin bgStr="5"
                    :type="viporlogin"
                    v-show="!canSee"></viporlogin>
      </template>
      <el-table-column label="序号"
                       align="center"
                       width="55">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="zhengShBH"
                       :show-overflow-tooltip="true"
                       align="center"
                       label="资质证书编号"></el-table-column>
      <el-table-column prop="strTopLevelName"
                       label="资质类别"
                       align="center"></el-table-column>
      <el-table-column prop="strQualifyName"
                       label="资质名称"
                       align="center"></el-table-column>
      <el-table-column prop="dengJName"
                       align="center"
                       label="资质等级"
                       width="130"></el-table-column>
      <el-table-column prop="faZhRQ"
                       label="发证日期"
                       align="center"
                       width="180">
        <template slot-scope="scope">{{scope.row.faZhRQ | formatterDate}}</template>
      </el-table-column>
      <el-table-column prop="youXQZhZhRQ"
                       label="证书有效期"
                       align="center"
                       width="180">
        <template slot-scope="scope">{{scope.row.youXQZhZhRQ | formatterDate}}</template>
      </el-table-column>
      <el-table-column prop="faZhJG"
                       label="发证凭证机关"
                       align="center"
                       width="220"></el-table-column>
    </el-table>
    <div class="company-page">
      <el-pagination layout="prev, slot, next"
                     background
                     :total="qualificationCnt"
                     @current-change="handleCurrentChange"
                     :current-page.sync="currentPage"
                     :hide-on-single-page="true">
        <span class="current-page">{{currentPage}}</span>
      </el-pagination>
    </div>

  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import { getQualifyList4Company, getInfoTree } from '@/api/organization'
import Bus from './bus.js'
export default {
  name: 'qualification',
  data() {
    return {
      currentPage: 1,
      qualificationList: [],
      searchParams: {
        operateID: 1,
        start: 0,
        limit: 10,
        gongSBH: '',
        strQualifyName: '',
        strTopLevelCode: ''
      },
      qualificationCnt: 0,
      noData: false,
      canSee: true,
      viporlogin: 'login',
      qualifyCategoryList: [],
      firstLoad: true
    }
  },
  components: {
    noData,
    viporlogin
  },
  computed: {
    ...mapGetters({
      memberType: 'memberType'
    })
  },
  methods: {
    onSearch() {
      this.searchParams.start = 0
      this.currentPage = 1
      this.getQualifyList()
    },
    handleCurrentChange(val) {
      this.searchParams.start = (val - 1) * this.searchParams.limit
      this.getQualifyList()
    },
    getQualifyList() {
      if (this.searchParams.date) {
        this.searchParams.startTime = this.searchParams.date[0] + ' 00:00:00'
        this.searchParams.endTime = this.searchParams.date[1] + ' 23:59:59'
      } else {
        this.searchParams.startTime = ''
        this.searchParams.endTime = ''
      }
      getQualifyList4Company(this.searchParams).then(data => {
        if (data.ok) {
          this.qualificationList = data.lst
          this.qualificationCnt = data.cnt
          if (data.cnt === 0) {
            this.noData = true
          }

          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'qulification', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          this.$notify.error({
            title: '错误',
            message: data.msg
          })
        }
      }).catch(error => {
        this.$notify.error({
          title: '错误',
          message: error
        })
      })
    },
    authCheck() {
      const authObj = authenticate('orgCert', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getQualifyList()
        this.canSee = true
        const params = {
          operateID: 1,
          id: 0
        }
        getInfoTree(params).then(data => {
          if (data.ok) {
            this.qualifyCategoryList = data.lst
          } else {
            this.$notify.error({
              title: '错误',
              message: data.msg
            })
          }
        }).catch(error => {
          console.log(error)
        })
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    }
  },
  mounted() {
    Bus.$on('companyInfo', data => {
      this.searchParams.gongSBH = data.companyID
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck()
          })
        } else {
          this.authCheck()
        }
      } else {
        this.authCheck()
      }
    })
  }
}

</script>
<style lang='scss' scoped>
/deep/ .el-table__empty-text {
  width: 100%;
}
.company-page {
  margin-top: 5px;
  text-align: right;
}
</style>
