<!--
 * @Date: 2020-03-27 17:55:28
 * @LastEditors: wxb
 * @LastEditTime: 2021-10-26 23:43:04
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\components\document.vue
 -->
<template>
  <div class="containner">
    <div class="search-panel">
      <el-form :inline="true"
               size="medium"
               :model="searchForm"
               @submit.native.prevent>
        <el-form-item>
          <el-input v-model="searchForm.fileName"
                    clearable
                    placeholder="请输入文档名称查询"></el-input>
        </el-form-item>
        <el-form-item>
          <el-select v-model="searchForm.industryType"
                     clearable
                     placeholder="请选择行业分类">
            <el-option v-for="(item,index) in projectType"
                       :key="index"
                       :label="item.DICTIONARYITEM_ITEMNAME"
                       :value="item.DICTIONARYITEM_ITEMCODE"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData"
              border
              style="width: 100%">
      <template slot="empty">
        <noData tips="截至当前日期，该板块下无信息公布，请持续关注，全量信息持续更新"
                v-show="noData"></noData>
        <viporlogin bgStr="5"
                    :type="viporlogin"
                    v-show="!canSee"></viporlogin>
      </template>
      <el-table-column label="序号"
                       width="50"
                       align="center">
        <template slot-scope="scope">{{scope.$index+1}}</template>
      </el-table-column>
      <el-table-column prop="fileName"
                       label="名称"
                       align="center">
        <template slot-scope="scope">
          <el-button type="text"
                     size="small"
                     @click="handleClickItem(scope.row.fileList)"
                     :title="scope.row.fileName">{{scope.row.fileName}}</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="industryTypeName"
                       label="行业分类"
                       align="center">
      </el-table-column>
      <!-- <el-table-column align="center"
                         label="操作"
                         width="120">
          <template slot-scope="scope">
            <el-button @click="handleClickLook(scope.row)"
                       type="text"
                       size="small">查看</el-button>
            <el-button @click="handleClickDownload(scope.row)"
                       type="text"
                       size="small">下载</el-button>
          </template>
        </el-table-column> -->
    </el-table>
    <div class="company-page">
      <el-pagination layout="prev, slot, next"
                     background
                     :total="cnt"
                     @current-change="handleCurrentChange"
                     :hide-on-single-page="true"
                     :current-page.sync="currentPage">
        <span class="current-page">{{currentPage}}</span>
      </el-pagination>
    </div>
    <dialogviporlogin ref="dialogviporlogin"></dialogviporlogin>
  </div>
</template>

<script>
import { authenticate } from '@/utils/authenticate'
import { getToken } from '@/utils/auth'
import noData from '@/components/noData'
import viporlogin from '@/components/viporlogin'
import { mapGetters } from 'vuex'
import dialogviporlogin from '@/components/dialogviporlogin'
import Bus from './bus.js'
import { getCompanyFiles } from '@/api/organization'
export default {
  data() {
    return {
      currentPage: 1,
      tableData: [],
      searchForm: {
        start: 0,
        limit: 10,
        manufactor: '',
        fileName: '',
        industryType: ''
      },
      cnt: 0,
      noData: false,
      canSee: true,
      viporlogin: 'login',
      firstLoad: true
    }
  },
  components: {
    noData,
    viporlogin,
    dialogviporlogin
  },
  computed: {
    ...mapGetters({
      projectType: 'projectType',
      memberType: 'memberType'
    })
  },
  mounted() {
    if (this.projectType.length === 0) {
      this.$store.dispatch('getProjectType')
    }
    Bus.$on('companyInfo', data => {
      this.searchForm.manufactor = data.mingCh
      if (getToken()) {
        if (this.memberType === 'tourist') {
          this.$store.dispatch('getMemberInfo').then(res => {
            this.authCheck()
          })
          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'document', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          this.authCheck()
        }
      } else {
        this.authCheck()
      }
    })
  },
  methods: {
    onSearch() {
      this.searchForm.start = 0
      this.currentPage = 1
      this.getList()
    },
    getList() {
      if (this.searchForm.industryType === 'all') {
        this.searchForm.industryType = ''
      }
      getCompanyFiles(this.searchForm).then(data => {
        if (data.ok) {
          this.tableData = data.lst
          this.cnt = data.cnt
          if (data.cnt === 0) {
            this.noData = true
          }
          if (this.firstLoad) {
            Bus.$emit('badgeInfo', { type: 'document', cnt: data.cnt })
            this.firstLoad = false
          }
        } else {
          console.log(data.msg)
        }
      }).catch(error => {
        console.error(error)
      })
    },
    handleCurrentChange(val) {
      this.searchForm.start = (val - 1) * this.searchForm.limit
      this.getList()
    },
    // handleClickLook(info) {
    //   const authObj = authenticate('orgDocument:Look', this.memberType)
    //   if (authObj.flag) {
    //     // 调用查看相关方法
    //     console.log(info)
    //     this.$message('调用查看')
    //   } else {
    //     this.$refs.dialogviporlogin.handleShow(authObj.msg)
    //   }
    // },
    // handleClickDownload(info) {
    //   const authObj = authenticate('orgDocument:Download', this.memberType)
    //   if (authObj.flag) {
    //     // 调用查看下载方法
    //     console.log(info)
    //     this.$message('调用下载')
    //   } else {
    //     this.$refs.dialogviporlogin.handleShow(authObj.msg)
    //   }
    // },
    authCheck() {
      const authObj = authenticate('orgDocument', this.memberType)
      if (authObj.flag) {
        // 调用查询列表接口
        this.getList()
        this.canSee = true
      } else {
        // 根据msg值不同展示不同的提示信息
        this.canSee = false
        this.viporlogin = authObj.msg
      }
    },
    handleClickItem(val) {
      const filekey = val[0].fileID
      const routeData = this.$router.resolve({
        name: 'preview',
        params: {
          filekey
        }
      })
      window.open(routeData.href, '_blank')
    }
  }
}
</script>
<style lang='scss' scoped>
/deep/ .el-table__empty-text {
  width: 100%;
}

.company-page {
  margin-top: 5px;
  text-align: right;
}
</style>
