<!--
 * @Author: wxb
 * @Date: 2021-06-21 17:17:30
 * @LastEditTime: 2021-10-22 14:59:25
 * @LastEditors: wxb
 * @Description:
 * @FilePath: \ui-projectd:\projects\zhzj\ui-djz\src\views\Organization\orgDetail.vue
-->
<template>
  <div class="containner">
    <headerNav :activeIdx='2'></headerNav>
    <div class="content">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{path:'/orgSearch'}">机构信息</el-breadcrumb-item>
        <el-breadcrumb-item>机构详情</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="company-detail">
        <div class="company-title">{{companyDetail.mingCh}}</div>
        <el-descriptions :column="2"
                         size="medium"
                         border
                         class="company-info">
          <el-descriptions-item label-class-name="company-info-label"
                                content-class-name="company-info-content">
            <template slot="label">
              统一社会信用代码：
            </template>
            {{companyDetail.tongYShHXYDM}}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="company-info-label"
                                content-class-name="company-info-content">
            <template slot="label">
              经营状态：
            </template>
            {{companyDetail.jingYZhT}}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="company-info-label"
                                content-class-name="company-info-content">
            <template slot="label">
              机构法定代表人：
            </template>
            {{companyDetail.faRXM}}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="company-info-label"
                                content-class-name="company-info-content">
            <template slot="label">
              机构注册金额：
            </template>
            {{companyDetail.zhuCZB}} {{companyDetail.currency}}
          </el-descriptions-item>
          <el-descriptions-item label-class-name="company-info-label"
                                content-class-name="company-info-content">
            <template slot="label">
              机构注册地址：
            </template>
            {{companyDetail.diZh}}
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="company-tabs">
        <div class="tab-list"
             :class="{flextab:isFixed}">
          <div class="tab-list-item"
               @click="handleClickTab(item.id)"
               v-for="(item,index) in tabs"
               :key="index"
               :class="{active:activeTabs===item.id}">
            <span>{{item.name}}（{{item.badge}}）</span>
          </div>
        </div>

        <div class="tab-con">
          <div class="tab-con-item"
               ref="tabcon1">
            <div class="tab-con-item-title">
              <span class="title-name">机构资质</span>
              <span class="title-badge">（{{badge.qulification}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 资质信息 -->
              <qulification></qulification>
            </div>
          </div>
          <div class="tab-con-item"
               ref="tabcon2">
            <div class="tab-con-item-title">
              <span class="title-name">注册人员</span>
              <span class="title-badge">（{{badge.register}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 注册人员 -->
              <registerPerson></registerPerson>
            </div>
          </div>
          <div class="tab-con-item"
               ref="tabcon3">
            <div class="tab-con-item-title">
              <span class="title-name">荣誉奖项</span>
              <span class="title-badge">（{{badge.awards}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 奖项 -->
              <awards></awards>
            </div>
          </div>
          <div class="tab-con-item"
               ref="tabcon4">
            <div class="tab-con-item-title">
              <span class="title-name">既往业绩</span>
              <span class="title-badge">（{{badge.pastpro}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 既往项目业绩 -->
              <pastProjects></pastProjects>
            </div>
          </div>
          <div class="tab-con-item"
               ref="tabcon5">
            <div class="tab-con-item-title">
              <span class="title-name">机构联系人</span>
              <span class="title-badge">（{{badge.linkman}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 联系人 -->
              <person-link></person-link>
            </div>
          </div>
          <div class="tab-con-item"
               ref="tabcon6">
            <div class="tab-con-item-title">
              <span class="title-name">参与展会</span>
              <span class="title-badge">（{{badge.exhi}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 展会 -->
              <exhi></exhi>
            </div>
          </div>
          <div class="tab-con-item"
               ref="tabcon7">
            <div class="tab-con-item-title">
              <span class="title-name">机构文档</span>
              <span class="title-badge">（{{badge.document}}）</span>
            </div>
            <div class="tab-con-item-content">
              <!-- 机构文档 -->
              <document></document>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footerNav></footerNav>
  </div>
</template>

<script>
import headerNav from '@/components/headerNavBlue'
import footerNav from '@/components/footerNav'
import qulification from './components/qualification'
import awards from './components/awards'
import pastProjects from './components/pastProjects'
import personLink from './components/personLink'
import exhi from './components/exhi'
import document from './components/document'
import registerPerson from './components/registerPerson'
import Bus from './components/bus.js'
import {
  showCompanyByID
} from '@/api/organization'
export default {
  name: 'orgDetail',
  components: {
    headerNav,
    footerNav,
    qulification,
    awards,
    pastProjects,
    personLink,
    exhi,
    document,
    registerPerson
  },
  data() {
    return {
      isFixed: false,
      companyDetail: {},
      tabs: [{
        id: 1,
        name: '机构资质',
        badge: 0
      }, {
        id: 2,
        name: '注册人员',
        badge: 0
      }, {
        id: 3,
        name: '荣誉奖项',
        badge: 0
      }, {
        id: 4,
        name: '既往业绩',
        badge: 0
      }, {
        id: 5,
        name: '机构联系人',
        badge: 0
      }, {
        id: 6,
        name: '参与展会',
        badge: 0
      }, {
        id: 7,
        name: '机构文档',
        badge: 0
      }],
      badge: {
        qulification: 0,
        register: 0,
        awards: 0,
        pastpro: 0,
        linkman: 0,
        exhi: 0,
        document: 0
      },
      activeTabs: 1,
      // 点击导航栏时，停止监听页面滚动事件
      listBoxState: true
    }
  },
  mounted() {
    this.companyID = this.$route.params.id
    showCompanyByID({ id: this.companyID }).then(data => {
      if (data.ok) {
        this.companyDetail = {
          mingCh: data.mingCh,
          tongYShHXYDM: data.tongYShHXYDM,
          faRXM: data.faRXM,
          diZh: data.diZh,
          zhuCZB: data.zhuCZB,
          currency: data.currency,
          describe: data.describe,
          companyID: data.id,
          jingYZhT: data.jingYZhT
        }
        Bus.$emit('companyInfo', this.companyDetail)
      } else {
        this.$notify.error({
          title: '错误',
          message: data.msg
        })
      }
    }).catch(error => {
      this.$notify.error({
        title: '错误',
        message: error
      })
    })
    this.$nextTick(() => {
      let timeId
      window.addEventListener('scroll', () => {
        // 页面滚动停止100毫秒后才会执行下面的函数。
        clearTimeout(timeId)
        timeId = setTimeout(() => {
          this.handleScroll()
        }, 100)
      })
    })
    Bus.$on('badgeInfo', data => {
      const type = data.type
      const cnt = data.cnt
      switch (type) {
        case 'qulification':
          this.tabs[0].badge = cnt
          this.badge.qulification = cnt
          break
        case 'register':
          this.tabs[1].badge = cnt
          this.badge.register = cnt
          break
        case 'awards':
          this.tabs[2].badge = cnt
          this.badge.awards = cnt
          break
        case 'pastpro':
          this.tabs[3].badge = cnt
          this.badge.pastpro = cnt
          break
        case 'linkman':
          this.tabs[4].badge = cnt
          this.badge.linkman = cnt
          break
        case 'exhi':
          this.tabs[5].badge = cnt
          this.badge.exhi = cnt
          break
        case 'document':
          this.tabs[6].badge = cnt
          this.badge.document = cnt
          break
      }
    })
  },
  methods: {
    handleClickTab(index) {
      this.activeTabs = index
      this.listBoxState = false
      const tabId = `tabcon${index}`
      this.$refs[tabId].scrollIntoView({
        behavior: 'smooth', // 平滑过渡
        block: 'center' // 上边框与视窗中部平齐。默认值
      })
      let timeId = null
      clearTimeout(timeId)
      timeId = setTimeout(() => {
        this.listBoxState = true
      }, 1000)
    },
    handleScroll() {
      const scrollTop = window.pageYOffset
      if (scrollTop > 329) {
        this.isFixed = true
      } else {
        this.isFixed = false
      }

      if (this.listBoxState) {
        for (const item of this.tabs) {
          const tabId = `tabcon${item.id}`
          // 获取监听元素距离视窗顶部距离
          const offsetTop = this.$refs[tabId].offsetTop
          // 获取监听元素本身高度
          const scrollHeight = this.$refs[tabId].scrollHeight

          // 则表示页面已经滚动到可视区了。
          if (scrollTop + 259 >= offsetTop && scrollTop <= (offsetTop + scrollHeight)) {
            // 导航栏背景色选中
            this.activeTabs = item.id
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 144rem;
  margin: 0 auto;
}

.el-breadcrumb {
  height: 5.4rem;
  line-height: 5.4rem;
}

.company {
  &-detail {
    border: 1px solid #eee;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 5px 10px;
  }

  &-title {
    font-size: 16px;
    color: #398ce2;
    font-weight: 700;
    margin-top: 10px;
  }

  &-info {
    margin-top: 10px;

    /deep/ &-label {
      width: 150px;
      text-align: right !important;
    }
  }

  &-tabs {
    margin-top: 10px;
    margin-bottom: 10px;
    .tab-list {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fff;
      border: 1px solid #eee;
      border-bottom: none;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      z-index: 999999;

      &.flextab {
        position: fixed;
        top: 0;
        width: 1440px;
      }

      &-item {
        flex: 1;
        padding: 15px 10px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        &.active {
          color: #398ce2;
          border-bottom: 2px solid #398ce2;
          font-weight: 700;
        }
      }
    }

    .tab-con {
      &-item {
        margin-top: 10px;
        border: 1px solid #eee;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        min-height: 500px;
        &-title {
          padding: 10px 10px;
          font-size: 14px;
          border-bottom: 1px solid #eee;

          .title {
            &-name {
              display: inline-block;
              border-left: 2px solid #398ce2;
              padding-left: 10px;
            }
          }
        }

        &-content {
          padding: 5px 10px;
        }
      }
    }

    // /deep/ .el-tabs__content {
    //   min-height: 400px;
    // }
  }
  /deep/ &-page {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
